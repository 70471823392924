.unassigned-systems-alert {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: #000;
    font-weight: 600;

    button {
        background-color: transparent;
        color: #000;
        font-weight: 600;
        border-color: #000;

        &:hover {
            background-color: transparent;
        }
    }
}

.main-content {
    z-index: 1;

    .list-actions {
        display: flex;
        align-items: center;
        margin: 20px 0;
        gap: 20px;
        padding: 0px;

        .add-site-btn {
            &:after {
                content: " + "
            }

            padding: 6px 16px;
            background: #478CD1;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            margin-left: auto;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .header-dropdown {
            &>#dropdown-basic {
                background-color: #fff;
                color: black;
                display: flex;
                flex-direction: row;
                width: 222px;
                justify-content: space-between;
                align-items: center;
                height: 30px;
            }
            &>.dropdown-menu {
                width: fit-content;
            }
        }

        .header-bottom-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
}

.modal {
    overflow-y: auto !important;

    .form-check-input {
        height: 15px;
        width: 15px;
    }

    .modal-dialog {
        max-width: 100% !important;
        height: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

        .modal-content {
            width: 930px;
            height: 531px;
            overflow: hidden;
            .modal-header{
                padding: 15px 30px;
            }

            .modal-body {
                padding: 15px 30px;
                width: 100%;

                .site-modal-wrapper {
                    .add-site-form {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        gap: 30px;
                        height: max-content;

                        .input {
                            width: 50%;
                            margin-bottom: 0px;
                            flex-direction: column;
                            gap: 10px;

                            .name-wrapper {
                                width: 100%;
                                display: flex;
                                gap: 10px;
                                flex-direction: column;

                                .text-wrapper {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                }
                            }

                            .map-wrapper {
                                display: flex;
                                gap: 10px;
                                flex-direction: column;
                                .text-wrapper {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .bottom-controller {
                        height: 70px;
                        position: sticky;
                        bottom: 0px;
                        z-index: 2;
                        background: #FFF;
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        gap: 20px;

                        .cancel-modal {
                            height: 30px;
                            width: 100px;
                            border-radius: 4px;
                            border: 1px solid #478CD1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #478CD1;
                            cursor: pointer;
                        }

                        .submit-modal {
                            height: 30px;
                            width: 100px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #478CD1;
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.map-location-modal.modal {
    overflow: hidden;
    width: 100%;

    .modal-dialog .modal-content {
        width: 480px;
    }
}

.site-list-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .site-list-header {
        .title {
            padding: 10px 0;
            font-weight: bold;
            font-size: 20px;
        }

        .search-box {
            display: flex;
            justify-content: space-between;
            width: 20em;
            padding: 10px 20px;
            border: 1px solid;
            border-radius: 4px;
            max-height: 52px;
            align-items: center;

            .search-text {
                font-size: 14px;
            }

            .submit-btn {
                padding: 2px 8px;
                border: 1px solid;
                border-radius: 4px;
                background-color: black;
                color: white;
                cursor: pointer;
                font-size: 12px;
            }
        }

    }

    .site-list {
        padding: 0px;
        margin: 10px 0;
        width: 100%;
        display: flex;
        gap: 20px;

        .no-sites {
            text-align: center;
            width: 100%;
            background-color: #fff;
            border-radius: 6px;
            padding: 10px 20px;
        }

        .site-grid {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin: 0;
            justify-content: space-around;
            align-items: center;

            .site .tree-arrow {
                display: inline-block;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 9px solid #000;
                margin: 0 10px;
                transform: rotate(30deg);
            }

            .site.open .tree-arrow {
                transform: rotate(0deg);
            }

            .site {
                margin: 0 10px 40px 10px;
                background: #fff;
                text-align: left;
                box-shadow: 0px 0px 4px 2px #00000040;
                border-radius: 6px;
                width: 100%;
                max-width: 300px;

                &.open .site-row {
                    border-bottom: 1px solid #aaa;
                }
                cursor: pointer;
                
                .site-row {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;

                    .site-name-wrap {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .site-name {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                        }

                        .site-img {
                            width: 100%;
                            height: 210px;
                            object-fit: contain;
                        }

                        .system-active-wrapper {
                            padding: 5px 10px;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            width: fit-content;
                            border-radius: 6px;
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                        }
                        .greenTag{
                            background: #60FF79;
                        }
                        .orangeTag{
                            background: #FFCA7A;
                        }
                        .redTag{
                            background: #E14942;
                        }
                        .system-active {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                        }
                    }

                    .actions-wrap {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        padding-top: 20px;
                        padding-left: 20px;
                        padding-bottom: 24px;
                        padding-right: 20px;
                        position: relative;

                        .site-name {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                        }

                        .icon-wrapper {
                            display: flex;
                            gap: 10px;

                            .location-btn {
                                padding: 2px;
                                background-color: #478CD1;
                                width: 26px;
                                height: 26px;

                                img {
                                    margin-bottom: 3px;
                                }
                            }

                            .listcard-more-wrapper{
                                cursor: pointer;

                                .more-options-wrapper{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                    width: max-content;
                                    position: absolute;
                                    background: #fff;
                                    padding: 16px 14px;
                                    z-index: 1;
                                    top: 50px;
                                    right: -18px;
                                    border: 1px solid #bbb;
                                    border-radius: 4px;

                                    .more-options-row{
                                        display: flex;
                                        flex-direction: row;
                                        gap: 10px;
                                    }
                                }
                            }
                        }

                        .show-systems-label {
                            cursor: pointer;

                            &.tree-arrow {
                                margin: -1px 6px;
                            }
                        }

                        .actions {
                            display: flex;
                            justify-content: flex-start;
                            gap: 10px;

                            .action-item {
                                cursor: pointer;
                                display: inline-block;
                                font-size: 1.2em;

                                &.fa-trash {
                                    color: #ff0000;
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    border: none;
                }
            }
        }

        .no-systems {
            padding: 0 10px;
        }

        .list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            gap: 10px;
            max-height: 164px;
            overflow-y: auto;
            padding: 10px;

            li {
                width: 100%;

                .system {
                    display: flex;
                    padding: 5px 0;
                    cursor: pointer;
                    border-bottom: 1px solid #ccc;
                    text-align: left;

                    &:last-of-type {
                        border: none;
                    }

                    &.no-systems {
                        border: none;
                    }

                    .system-name-wrap {
                        flex: 1;

                        .system-name {
                            text-overflow: ellipsis;
                            max-width: 68px;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }

                    .system-metrics {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        flex: 1 1;
                        justify-content: center;

                        .system-metric {}

                        .system-status {
                            width: 9px;
                            height: 9px;
                            display: inline-block;
                            background-color: red;
                            border-radius: 50%;

                            &.healthy {
                                background-color: green;
                            }
                        }
                    }

                    .actions {
                        flex: 1;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 10px;

                        .action-item {
                            font-size: 1.2em;

                            &.fa-trash {
                                color: #ff0000;
                            }
                        }

                    }
                }
            }

            .add-new-system {
                display: inline-flex;
                cursor: pointer;
                border: 1px solid rgb(26, 115, 232);
                color: rgb(26, 115, 232);
                padding: 0.175rem 0.5rem;
                line-height: 1.4;
                font-size: 14px;
                font-weight: 500;
                border-radius: 6px;
                width: 134px;
            }
        }
    }
}

.map-container {
    width: 420px;
    height: 350px;
    position: relative;

    .map {
        width: 100%;
        height: 350px;
    }

    .map-current-location-btn {
        position: absolute;
        bottom: 10px;
        left: 10px;

        .map-crosshairs {
            font-size: 0.9rem;
            color: white;
            margin-left: 5px;
        }
    }
}

// for mobile screens
@media only screen and (max-width: 840px) {
    .main-content {
        .list-actions {
            gap: 10px !important;
        }
    }

    .site-list-wrap {
        font-size: 12px;
        width: 100%;

        .site-list {
            justify-content: center;
            .site-grid {
                width: fit-content;
                grid-template-columns: auto;
                gap: 0px;

                .site {
                    width: 100%;

                    .site-row {
                        .actions-wrap {
                            .icon-wrapper {
                                .listcard-more-wrapper {
                                    .more-options-wrapper {
                                        right: 7px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .list .add-new-system {
                padding: 5px;
                font-size: 10px;
                width: 94px;
            }
        }
    }

    .modal {
        .modal-dialog{
            .modal-content{
                .modal-body{
                    .site-modal-wrapper{
                        .add-site-form{
                            height: 500px;
                            flex-direction: column;
                            overflow: scroll;
                            .input{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .map-container {
        width: 100%;
    }
}
