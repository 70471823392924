.request-otp-again-wrap {
  display: flex;
  gap: 5px;
  margin: 10px 0;

  .request-otp-again-timer {
    font-size: 14px;
  }

  .request-otp-again-btn {
    padding: 0;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.request-otp-btn, .reset-pwd-btn {
  padding: 5px 10px;
  font-size: 16px;
  width: 244px;
  height: 50px;
  border-radius: 25px;

  &.disabled {
    opacity: 0.5;
  }
}
