body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;

  overflow: hidden;
  position: relative;

  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col-flex {
  display: flex;
  flex-direction: column;
}

.action-btn {
  border-radius: 4px;
  background-color: #4985df;
  margin-right: 10px;
  color: #fff;
  border-color: #4985df;
  font-weight: bold;

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.btn {
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;

  &-primary {
    background-color: #2491EB;
  }
}

.main-content {
  margin: 0 1rem;
}

.block-ui-container {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .block-ui-overlay {
    opacity: 0.3;
    background-color: transparent;
  }

  .block-ui-message-container {
    text-align: center;

    .block-ui-message {
      color: #000;
      font-weight: bold;
    }
  }
}

.input {
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  input {
    width: 100%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #aaa;
    text-indent: 10px;

    &.read-only {
      cursor: not-allowed;
    }
  }

  .date-input {
    text-indent: 5px;
    width: 130px;
  }

  .input-label {
    font-size: 10px;
    position: absolute;
    top: -15px;
    left: 37px;
    font-weight: bold;
  }
}

.alert {
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

body::after {
  content: '';
  display: block;
  height: 50px; /* Set same as footer's height */
}

.arrow {
  border: solid #888;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.medium-bold {
  border-color: #000;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.form-errors {
  display: flex;
  flex-direction: column;

  .form-error {
    color: #aa0000;
  }
}

.header-dropdown {
  .dropdown-selected-option-text {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.list-container {
  overflow: visible;
  border: 0 !important;
  text-align: left;
  box-shadow: 0px 0px 4px 1px #00000040;
  border-collapse: collapse;
  border-radius: 10px;
  font-size: 14px;
  width: min-content;

  .list-header {
      overflow: visible;
      display: grid;
      grid-template-columns: repeat(5, 1fr); // Adjust the number of columns as needed
      background-color: rgba(#478CD1, 0.2);
      border-radius: 10px 10px 0 0;
      padding: 10px;
      margin: 0 !important;

      .list-header-item {
          border: 0;

          display: flex;
          justify-content: center;
      }
  }

  .list-body {
      .list-body-row {
          overflow: visible;
          display: grid;
          grid-template-columns: repeat(5, 1fr); // Adjust the number of columns as needed
          justify-content: center;
          border: 0;
          border-bottom: 1px solid #ddd;
          padding: 15px 10px;
          margin: 0 !important;
      }

      .list-body-cell {
          border: 0;
          display: flex;
          justify-content: center;
          padding: 5px;
      }
  }
}

@media only screen and (max-width: 840px) {
  .list-container {
    width: 100%;
  }

  .btn {
    font-size: 10px;
    padding: 0 5px;
  }

  .modal {
    font-size: 12px;
  }

  .modal-title {
    font-size: 14px;
  }

  .input {
    .date-input {
      width: 100px;
    }
  }
}
