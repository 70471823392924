.device-config-list-wrap {
  margin: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  

  .actions-edit {
    cursor: pointer;
  }

  .header-dropdown {
    margin: 0 auto;
  }

  .sensorName-column {
    max-width: 100px;
    min-width: 100px;
  }

  .plugLetter-column {
    max-width: 120px;
  }

  .pinNumber-column {
    max-width: 120px;
  }

  .sensorTypeName-column {
    max-width: 250px;
  }

  .channelName-column {
    max-width: 250px;
  }
}

.add-sensor-type-btn {
  &:after {
    content: " + "
  }

  padding: 6px 16px;
  background: #478CD1;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.edit-sensor-config-btn {
  padding: 6px 16px;
  background: #478CD1;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-left: auto;
}

.cancel-edit-sensor-config-btn {
  padding: 6px 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.edit-sensor-config-modal {
  overflow: hidden !important;
}

.edit-sensor-config-modal-wrapper, .add-sensor-type-modal-wrapper {
  overflow-y: auto;

  .input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    .name-wrapper {
      width: 222px;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .text-wrapper {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .bottom-controller {
    height: 70px;
    position: sticky;
    bottom: 0px;
    z-index: 2;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;

    .cancel-modal {
      height: 30px;
      width: 100px;
      border-radius: 4px;
      border: 1px solid #478CD1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #478CD1;
      cursor: pointer;
    }

    .submit-modal {
      height: 30px;
      width: 100px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #478CD1;
      color: #fff;
      cursor: pointer;
    }
  }
}

.list-body-cell, .list-header-item{
  display: flex;
  align-items: center;
  justify-content: center;
}

.device-config-list-wrap {
  overflow-x: auto;
  margin: 20px 0;
}

.list-container {
  margin-top: 1rem;

  .sensorName-column {
    max-width: 100px;
    min-width: 100px;
  }

  .plugLetter-column {
    max-width: 120px;
    min-width: 120px;
  }

  .pinNumber-column {
    max-width: 120px;
    min-width: 120px;
  }

  .sensorTypeName-column {
    max-width: 250px;
    min-width: 250px;
  }

  .channelName-column {
    max-width: 250px;
    min-width: 250px;
  }
}

.list-header, .list-body-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.list-container {
  overflow-x: auto;
  overflow-y: visible;
}

.list-header, .list-body-row {
  width: min-content;
  overflow-x: auto;
  overflow-y: visible;
}

.list-header {
  font-weight: 600;
  font-size: 14px;
}

.main-content{
  margin-right: 1rem;
}

.site-detail-header-btn {
  margin: 0.5rem;
}

.site-detail-header-options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: nowrap;
}

@media screen and (max-width: 840px) {
  .main-content {
    .site-detail-header {
      flex-direction: column !important;
    }
  }

  .site-detail-header-options {
    flex-wrap: wrap;
  }

  .site-detail-header-btn {
    margin: 0.2rem 0.5rem;
  }
}