.site-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    width: 100vw;

    .site-content {
        text-align: center;
        padding: 16px;
        // overflow: auto;
    }

    .site-navbar-content {
        display: flex;
        flex-direction: column;
        .navbar{
            height: 80px;
        }
    }
}

// non-mobile screens
@media only screen and (min-width: 850px) {
    .site-container {
        .site-content {
            margin: 30px;
            width: 80%;
            padding: 0px;
        }

        .site-navbar-content {
            flex-direction: row;
            .navbar{
                height: 100vh;
            }
        }
    }
}
