.breadcrumb-wrapper {
    display: flex;
    align-items: center;

    .breadcrumb-container {
        display: flex;
        flex-direction: row;
        gap: 15px;

        .breadcrumb-home {
            cursor: pointer;
        }

        .breadcrumb-text-wrap {
            display: flex;
            gap: 15px;

            .breadcrumb-text {
                font-size: min(max(14px, 1vw), 24px);
                font-style: normal;
                font-weight: 600;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
                max-width: 20vw;
            }

            .breadcrumb-link {
                color: #478CD1;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 840px) {
    .breadcrumb-wrapper {
        width: fit-content;

        .breadcrumb-container {
            gap: 5px;

            .breadcrumb-home {
                width: 12px;
            }

            .breadcrumb-text-wrap {
                gap: 5px;

                .breadcrumb-text {
                    max-width: 150px;
                }
            }

            .breadcrumb-progress {
                width: 3px;
            }
        }
    }
}
