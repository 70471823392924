.header-nav-item {
  display: flex;
  gap: 10px;
  padding: 5px;
  align-items: center;

  .icon {
    width: 80px;
    height: 76px;
    object-fit: cover;
  }

  &-label {
    color: rgb(255, 255, 255);
  }
}
