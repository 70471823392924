.logger-list-container.list-container {
  .list-header {
    .list-header-item {

      &.name-column {
        min-width: 200px;
        max-width: 200px;
      }

      &.refrigerantType-column {
        min-width: 120px;
        max-width: 120px;
      }

      &.status-column {
        min-width: 120px;
        max-width: 120px;
      }

      &.health-column {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }

  .list-body {
    .list-body-cell {
      &.name-column {
        cursor: pointer;
        text-decoration: none;
        font-weight: 600;
        text-decoration-color: #478CD1;
        color: #478CD1;
      }

      &.health-column {
        min-width: 120px;
        max-width: 120px;

        img {
          padding: 8px;
          border-radius: 6px;
          width: 25px;
          height: 25px;

          &.unhealthy {
            background: #E14942;
          }

          &.healthy {
            background-color: #68B641;
          }
        }
      }

      &.name-column {
        min-width: 200px;
        max-width: 200px;
      }

      &.refrigerantType-column {
        min-width: 120px;
        max-width: 120px;
      }

      &.status-column {
        min-width: 120px;
        max-width: 120px;
      }

      &.health-column {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }
}
