.chart {
  position: relative;

  .chart-value {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    font-size: 36px;
    font-weight: 700;
  }
}
