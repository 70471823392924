.main-content {
	.unassigned-device-list {
    display: flex;
    justify-content: center;
    padding: 20px 0;

		.list-container {

			.list-header {
				margin-bottom: 10px;

				&-item {
					border: 0;
					text-align: left;
				}
			}

			.list-body {
				border: 0;

				.list-body-row {
					border: 0;
					min-height: 40px;

					.list-body-cell {
						border: 0;
						text-align: left;

						&.health-column {
							img {
								padding: 8px;
								background: #e14942;
								border-radius: 6px;
								width: 25px;
								height: 25px;
							}

							&.healthy img {
								background-color: #68b641;
							}
						}

						&.status-column {
							&.Online {
								color: #68b641;
							}

							&.Offline {
								color: #e14942;
							}
						}
					}
				}

				.actions-column {
					display: flex;
					justify-content: center;
					align-items: center;
	
          button {
						padding: 6px 16px;
						background: #478cd1;
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
            min-width: 90px;
					}
				}
			}

			.deviceId-column {
				min-width: 100px;
				max-width: 100px;
			}

			.status-column {
				min-width: 200px;
				max-width: 200px;
			}

			.health-column {
				min-width: 100px;
				max-width: 100px;
			}

			.actions-column {
				min-width: 200px;
				max-width: 200px;
			}
		}
	}
}
