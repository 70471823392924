.nav-item {
  padding: 0.5rem 0.625rem;
  margin: 0.09375rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  width: 100%;
  text-align: center;

  &.active {
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem;
  }
}
