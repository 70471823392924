.thermostat-list-header {
  display: flex;

  .add-thermostat-btn {
    &:after {
      content: " + ";
    }

    padding: 6px 16px;
    background: #478CD1;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-left: auto;
  }
}

.thermostat-list-wrap {
  margin: 40px 0;
  display: flex;
  justify-content: center;

  .save-icon {
    font-size: 18px;
  }

  .cancel-edit-icon {
    font-size: 18px;
  }

  .list-body {
    width: min-content;

    .list-body-row {
      display: flex;
    }

    .name-column {
      display: flex;
      flex-direction: column;

      .thermostat-name {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        text-decoration-color: #478CD1;
        color: #478CD1;
      }

      .thermostat-device-name {
        font-weight: 500;
      }
    }
  }

  .id-column {
    text-align: center;
    margin-bottom: 0;
    min-width: 60px;
    max-width: 60px;
  }

  .actions-column {
    display: flex;
    gap: 10px;
    align-items: center;

    &.input {
      margin-bottom: 0;
    }

    .edit-sensor-type-btn, .delete-sensor-type-btn, .save-sensor-type-btn, .cancel-sensor-type-btn {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }

  .name-column {
    min-width: 200px;
    max-width: 200px;
  }

  .actions-column {
    min-width: 200px;
    max-width: 200px;
  }

  .thermostatBrand-column {
    min-width: 150px;
    max-width: 150px;
  }

  .thermostatModelNumber-column {
    min-width: 100px;
    max-width: 100px;
  }

  .organizationName-column {
    min-width: 100px;
    max-width: 100px;
  }

  .siteName-column {
    min-width: 100px;
    max-width: 100px;
  }

  .actualTemp-column {
    min-width: 100px;
    max-width: 100px;
  }

  .heatSetpoint-column {
    min-width: 100px;
    max-width: 100px;
  }

  .coolSetpoint-column {
    min-width: 100px;
    max-width: 100px;
  }

  .edit-thermostat-btn, .delete-thermostat-btn {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
