.navbar {
  height: 100px;
  background: linear-gradient(195deg,#42424a,#191919);

  .navbar-toggler {
    &.collapsed {
      border: 0;
    }

    .navbar-toggler-icon {
      width: 1em;
      height: 1em;
    }
  }

  &-collapse {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 12px;
    margin-bottom: 5px;
    background: #3F4144;

    .navbar-item-wrapper{
      height: 60px;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 40px;

      .navbar-text {
        padding: 2px 14px;
        margin: 0;
        border-radius: 0.375rem;
        cursor: pointer;
        color: rgb(255, 255, 255);
        min-width: 2rem;
      }
    }

    .item-wrapper-active{
      background: #3F4144;
    }
  }

  .logout-wrapper{
    width: 100%;
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    height: 60px;
    cursor: pointer;

    .logout-text {
      color: #fff;
    }
  }

  .brand {
    display: flex;
    align-items: center;

    .icon {
      width: 140px;
      height: 58px;
      object-fit: cover;
    }

    &-label {
      font-size: 18px;
      color: rgb(255, 255, 255);
      margin-bottom: 1px;
    }
  }
}

@media only screen and (min-width: 850px) {
  .navbar {
    display: flex;
    flex-direction: column;
    min-width: 18%;
    background: #202124;
    box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    height: 100vh;

    &-collapse {
      background: #202124;
      margin-bottom: 0;
      font-size: 16px;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .navbar-text {
        padding: 0.5rem 0.625rem;
        min-width: 8rem;
      }
    }

    .brand {
      display: flex;
      align-items: center;
  
      .icon {
        width: 172px;
        height: 97px;
        object-fit: cover;
      }
    }
  }

  .brand-label {
    font-size: 24px;
    margin-bottom: 3px;
  }
}
