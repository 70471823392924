.list-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #aaa;

  margin: 1rem 0 !important;

  .list-header {
    display: flex;

    & > * {
      flex: 1;
      border-right: 1px solid #aaa;

      &.sortable-column {
        cursor: pointer;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .status-card {
    display: flex;
    justify-content: center;
  }

  .list-body {
    border-top: 1px solid #aaa;
    margin: 0;
    padding: 0;
    list-style-type: none;
    
    &-row {
      display: flex;
      border-bottom: 1px solid #aaa;

      &:last-child {
        border-bottom: 0;
      }
    }

    &-cell {
      flex: 1;
      border-right: 1px solid #aaa;

      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        border-right: none;
      }
    }

    &-empty {
      padding: 20px;
    }
  }
}
