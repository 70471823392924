.device-container {
    display: flex;
    justify-content: center;

    .detail {
        width: 50%;
        max-width: 600px;
        border-radius: 4px;
        padding: 8px 16px;
        background-color: #fff;

        .metric-name {
            font-weight: bold;
            color: #777;
        }

        .device-site-wrap {
            display: flex;
            justify-content: space-around;
            padding: 5px 0;

            .site-name {
                width: 50%;
                padding: 5px 0;
            }

            .device-name {
                width: 50%;
                padding: 5px 0;
            }
        }

        .therm-valve-wrap {
            display: flex;
            justify-content: space-around;
            border-top: 2px solid #aaa;
            padding: 5px 0;
        }

        .line-temps-wrap {
            display: flex;
            justify-content: space-around;
            border-top: 2px solid #aaa;
            padding: 5px 0;

            .temp {
                width: 42%;
                padding-top: 10px;
            }
        }

        .duty-cycle-wrap {
            display: flex;
            justify-content: space-around;
            border-top: 2px solid #aaa;
            padding: 5px 0;
        }

        .compressor-wrap {
            display: flex;
            justify-content: space-around;
            border-top: 2px solid #aaa;
            padding: 5px 0;
        }

        .action-btns {
            display: flex;
            justify-content: flex-end;
            border-top: 2px solid #aaa;
            padding-top: 10px;
            gap: 20px;
        }
    }
}

.device-detail-container {
    overflow: hidden;
}

.vertical-divider {
    border: 1px solid;
    color: #aaa;
}

.device-detail-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .button-controller{
        display: flex;
        flex-direction: row;
        gap: 20px;
        .edit-button-wrapper{
            width: 101px;
            height: 30px;
            border-radius: 6px;
            background: #478CD1;
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .edit-button-text{
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                color: #fff;
            }
            .edit-icon{
                width: 12px;
                height: 12px;
            }
        }
        .delete-button-wrapper{
            width: 101px;
            height: 30px;
            border-radius: 6px;
            border: 1px solid #BF2137;
            background: #FFF;
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .delete-button-text{
                color: #BF2137;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
            }
            .delete-icon{
                width: 12px;
                height: 12px;
            }
        }
    }
}

.device-detail-controller-wrapper{
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid rgba(71, 140, 209, 0.20);
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 0px 30px;
    margin-top: 36px;
    .device-name{
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
    }
    .device-detail-controller{
        display: flex;
        flex-direction: row;
        gap: 20px;

        .device-status {
            width: 101px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            color: #fff;
            border-radius: 6px;

            &.active {
                background: #68B641;
            }

            &.inactive {
                background: #E14942;
            }
        }

        .location-button{
            width: 101px;
            height: 28px;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: center;
            background: #478CD1;
            border-radius: 6px;
            cursor: pointer;
            .location-text{
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                color: #fff;
            }
            .location-icon{
                width: 11px;
                height: 14px;
            }
        }
        .time-history-wrapper{
            width: 126px;
            height: 28px;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: center;
            background: #478CD1;
            border-radius: 6px;
            cursor: pointer;
            .time-history-text{
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                color: #fff;
            }
            .time-history-icon{
                height: 15px;
                width: 15px;
            }
        }
    }
}

.carousel-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    flex-wrap: nowrap;

    gap: calc((100% - 1047px) / 3);
    position: relative;

    overflow: hidden;

    border-radius: 5px;


    background: transparent;

    .carousel-navigation-back-btn {
        cursor: pointer;
        position: absolute;
        left: 0;
        height: 100%;
        width: 40px;
        background: linear-gradient(90deg, white, transparent);

        img {
            padding-top: 90px;
        }

        &.disabled {
            cursor: not-allowed;
        }
    }

    .carousel-navigation-next-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        height: 100%;
        width: 40px;
        background: linear-gradient(90deg, transparent, white);

        img {
            padding-top: 90px
        }

        &.disabled {
            cursor: not-allowed;
        }
    }

    img.carousel-image {
        width: auto;
        max-height: 210px;
        border-radius: 6px;
        object-fit: contain;
        margin: 0 5px;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

    }
}

.carousel-image{
    .modal-dialog{
        .modal-content{
            width: min-content;
        }
    }
}

.dropdown-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.header-dropdown {
    .btn {
        font-weight: normal;
    }

    &.dropdown {
        width: 222px;
    }

    .dropdown-toggle::after {
        display: none;
    }

    & > #dropdown-basic {
        background-color: #fff;
        color: black;
        display: flex;
        flex-direction: row;
        width: 222px;
        justify-content: space-between;
        align-items: center;
        height: 30px;
    }

    &>.dropdown-menu {
        width: fit-content;
    }
}

.metric-detail-card-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;

    .metric-detail-card {
        border-radius: 6px;
        padding: 20px 30px;
        border: 1px solid rgba(71, 140, 209, 0.20);
        background: #FFF;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &.greenTag {
            border: 1px solid rgba(104, 182, 65, 0.20);
            background: #E8F4E3;
            box-shadow: 0px 0px 15px 0px rgba(104, 182, 65, 0.15);
        }

        &.redTag {
            border: 1px solid rgba(223, 72, 65, 0.20);
            background: #F9E1E0;
            box-shadow: 0px 0px 15px 0px rgba(223, 72, 65, 0.15);
        }

        .detail-card-img {
            width: 50px;
            height: 50px;
            margin-top: 10px;
        }

        &-name {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            justify-content: flex-start;

            &.greenColor {
                color: #68B641;
            }

            &.redColor {
                color: #DD4841;
            }
        }

        &-body {
            margin-top: 12px;
            color: #478CD1;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
        }
    }
}

.detail-card-wrapper {
    display: flex;
    flex-direction: row;
    gap: 21px;
    margin-top: 20px;

    .greenTag {
        border: 1px solid rgba(104, 182, 65, 0.20);
        background: #E8F4E3;
        box-shadow: 0px 0px 15px 0px rgba(104, 182, 65, 0.15);
    }

    .redTag {
        border: 1px solid rgba(223, 72, 65, 0.20);
        background: #F9E1E0;
        box-shadow: 0px 0px 15px 0px rgba(223, 72, 65, 0.15);
    }

    .detail-card {
        width: 100%;
        border-radius: 6px;
        padding: 20px 30px;

        .detail-card-internal {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .greenColor {
                color: #68B641;
            }

            .redColor {
                color: #DD4841;
            }

            .detail-card-text {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                display: flex;
                justify-content: flex-start;
            }

            .detail-card-img {
                width: 62px;
                height: 62px;
                margin: 20px;
            }
        }
    }
}

.bottom-detail-wrapper{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
    justify-content: space-between;

    .bottom-temp-wrapper{
        width: 24%;
        height: 288px;
        padding: 30px;
        border-radius: 6px;
        border: 1px solid rgba(71, 140, 209, 0.20);
        background: #FFF;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);

        .temp-text {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            justify-content: flex-start;
        }

        .temp-value-wrapper {
            display: flex;
            flex-direction: row;
            margin-top: 70px;
            gap: 10px;
            justify-content: center;

            .temp-value{
                display: flex;
                flex-direction: row;
                gap: 3px;

                .temp-value-text{
                    color: #478CD1;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }
    }

    .bottom-pressure-wrapper{
        padding: 30px;
        width: 890px;
        border-radius: 6px;
        border: 1px solid rgba(71, 140, 209, 0.20);
        background: #FFF;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        gap: 30px;
        .pressure-text{
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            justify-content: flex-start;
        }
        .gauge-chart-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .pressue-detail-text-wrapper{
                .pressure-detail-text{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                }
                .pressure-detail-subtext{
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                }
            }
        }
    }

    .bottom-duty-cycle-wrapper {
        width: 30%;
        height: 288px;
        padding: 30px;
        border-radius: 6px;
        border: 1px solid rgba(71, 140, 209, 0.20);
        background: #FFF;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);

        .header-text {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            display: flex;
            justify-content: flex-start;
        }

        .body-wrapper {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: center;
            height: 100%;

            .body-item {
                display: flex;
                flex-direction: row;
                gap: 3px;

                .body-item-text {
                    color: #478CD1;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }

        .run-time-table {
            text-align: left;
            width: 100%;

            .run-time-row {
                font-size: 15px;

                .run-time-metric-name {
                    font-weight: bold;
                }
            }
        }
    }
}

.carousel-image .modal-dialog {
    margin: 0;

    img {
        width: 100%;
        height: 80vh;
        object-fit: contain;
    }
}

// for mobile screens
@media only screen and (max-width: 840px) {
    .device-detail-header {
        gap: 10px;

        .button-controller {
            gap: 15px;
            width: fit-content;

            .edit-button-wrapper, .delete-button-wrapper {
                width: 30px;
            }

            .edit-button-text, .delete-button-text {
                display: none;
            }
        }
    }

    .device-detail-controller-wrapper {
        height: 65px;
        margin-top: 10px;
        padding: 0 16px;

        .device-name {
            font-size: 16px;
            direction: rtl;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 150px;
        }

        .device-detail-controller {
            gap: 5px;

            .device-status {
                width: 70px;
            }
        }
    }

    .carousel-wrapper{
        margin-top: 20px;
        display: flex;
        gap: 15px;

        img.carousel-image {
            width: 100%;
            height: 210px;
            border-radius: 6px;
        }
    }

    .bottom-detail-wrapper{
        flex-direction: column;
        .bottom-temp-wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .bottom-pressure-wrapper{
            width: 100%;
            .gauge-chart-wrapper{
                flex-direction: column;
                width: 100%;
                .pressue-detail-text-wrapper{
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .metric-detail-card-wrapper {
        grid-template-columns: repeat(2, 1fr);

        .metric-detail-card {

            &-body {
                justify-content: center;
            }
        }
    }

    .detail-card-wrapper{
        overflow: scroll;

        .detail-card {
            .detail-card-internal {
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }

    .device-container {
        font-size: 12px;

        .detail {
            width: 100%;

            .action-btns {
                gap: 10px;
            }
        }
    }

    .carousel-image .modal-dialog {
        img {
            height: 20vh !important;
            width: 80vw !important;
        }
    }
}
