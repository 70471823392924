.user-mgmt-container {
  overflow: auto;

  .arrow {
    margin-left: 5px;
    margin-bottom: 2px;

    &.up {
      margin-bottom: -2px;
    }
  }

  .user-list-header {
    display: flex;
    gap: 10px;
    margin: 20px 5px;
    padding: 0 20px;


    .add-user-btn {
      &:after {
        content: " + "
      }

      padding: 5px 10px;
      background: #478CD1;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      margin-left: auto;
    }
  }

  .user-list-center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .2rem;
  }

  .list-container {
    border: 0;
    font-size: 12px;

    width: fit-content;

    .actions-column {
      cursor: pointer;
      position: relative;
      width: 60px;

      .more-options-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: max-content;
        position: absolute;
        background: #fff;
        padding: 16px 14px;
        z-index: 10;
        top: 30px;
        right: 18px;
        border: 1px solid #bbb;
        border-radius: 4px;

        .more-options-row {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
    }


    .id-column {
      min-width: 60px;
      max-width: 60px;
    }
    
    .username-column {
      min-width: 250px;
      max-width: 250px;
    }

    .dateJoined-column {
      min-width: 200px;
      max-width: 200px;
    }

    .lastLogin-column {
      min-width: 200px;
      max-width: 200px;
    }

    .role-column {
      min-width: 160px;
      max-width: 160px;
    }

    .organization-column {
      min-width: 160px;
      max-width: 160px;
    }

    .actions-column {
      min-width: 80px;
      max-width: 80px;
    }

    .list-header {
      .list-header-item {
        border: 0;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 700;
        font-size: 12px;
      }
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      padding: 10px 0;
    }

    .list-body {
      border: 0;

      .form-check {
        .form-check-input {
          margin-left: unset;
        }

        label {
          display: none;
        }
      }

      .list-body-row {
        border: 0;
        padding: 2px 0;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        .list-body-cell {
          text-align: left;
          border: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &.id-column {
            font-weight: bold;
          }

          .name-text {
            font-weight: bold;
          }
        }
      }

      .actions-column {
        display: flex;
        justify-content: center;
        gap: 15px;

        .action-item {
          .action-item-icon {
            cursor: pointer;
          }
        }
      }
    }

    .list-header, .list-body {
      .list-header-item.checkbox-column .list-header-item-label, .checkbox-column {
        max-width: 36px;
        padding: 0 8px;

        .form-check {
          padding-left: 0;
        }
      }

      .list-header-item-label {
        margin-right: 2px;
        display: inline-block;
      }
    }
  }
}

.modal.add-user-modal, .modal.reset-pwd-modal {
  width: 100%;
  overflow: hidden;

  .modal-dialog .modal-content {
    width: 930px;
  }

  .add-user-form, .reset-pwd-form {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }

  .input {
    flex-direction: column;
    gap: 10px;

    .name-wrapper {
      width: 100%;
      display: flex;
      gap: 10px;
      flex-direction: column;

      .text-wrapper {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .role-dropdown, .org-dropdown {
      width: 100%;

      & > #dropdown-basic {
        background-color: #fff;
        color: black;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 30px;
      }

      & > .dropdown-menu {
        width: fit-content;
      }
    }
  }

  .action-btns {
    display: flex;
    gap: 20px;

    button {
      padding: 6px 16px;
      background: #478CD1;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      margin-left: auto;

      &.btn-secondary {
        background-color: #fff;
        color: #000;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .user-mgmt-container {
    font-size: 8px;
  }

  .alert {
    height: 28px;
    margin: 0;
    padding: 7px;
    font-size: 8px;
  }

  .sub-header {
    .search-container {
      width: 132px;
    }
  }

  .dateJoined-column, .lastLogin-column {
    display: none;
  }

  .user-mgmt-container .list-container {
    .id-column {
      max-width: 40px;
      min-width: 40px;
    }

    .username-colun {
      max-width: 100px;
      min-width: 100px;
    }

    .dateJoined-column {
      max-width: 120px;
      min-width: 120px;
    }

    .lastLogin-column {
      max-width: 80px;
      min-width: 80px;
    }

    .role-column {
      max-width: 80px;
      min-width: 80px;
    }

    .organization-column {
      max-width: 80px;
      min-width: 80px;
    }

    .actions-column {
      max-width: 40px;
      min-width: 40px;
    }
  }

  // .user-mgmt-container .list-container .list-body {



  //   .actions-column {
  //     gap: 5px;
  //     padding-left: 2px;
  //     padding-right: 2px;
  //     max-height: 13px;

  //     .action-item {
  //       max-height: 13px;
  //     }
  //   }
  // }

  .list-header-item {
    .arrow {
      padding: 2px;

      &.up {
        margin-top: 2px;
      }
    }
  }

  .user-mgmt-container {
    .list-header, .list-body {
      .list-header-item.checkbox-column .list-header-item-label, .checkbox-column {
        max-width: 20px !important;
        max-height: 13px;
        padding: 0 5px !important;
      }
    }

    .list-body-cell, .list-header-item {
      max-width: 53px;
    }

    .list-container {
      .actions-column {
        .more-options-wrapper {
          right: 13px;
          top: 20px;
        }
      }
    }
  }

  .modal {
    input {
      height: 28px;
    }
  }
}
