.file-uploader {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .upload-multi-btn-wrapper{
    width: 100%;
    display: flex;
    gap: 7px;
    flex-direction: row;
    flex-wrap: wrap;
    .upload-multi-btn{
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      cursor: pointer;
      border-radius: 6px;
      border: 0.8px dashed #478CD1;
      background: rgba(153, 153, 153, 0.10);
      .upload-plus-icon{
        width: 22px;
        height: 22px;
      }
    }
    .uploaded-image-preview-wrapper{
      .uploaded-image-preview{
        width: 100px;
        height: 100px;
      }
      .trash-icon{
        width: 25px;
        height: 25px;
        z-index: 1;
        margin: 5px;
        cursor: pointer;
      }
    }
  }

  .upload-btn{
    width: 100%;
    height: 176px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 0.8px dashed #478CD1;
    background: rgba(153, 153, 153, 0.10);
    cursor: pointer;
    .upload-btn-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .upload-img{
        height: 16px;
        width: 16px;
      }
      .upload-text{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
      .internal-upload-btn{
        width: 69px;
        font-weight: normal;
      }
    }
  }

  .selected-file-wrapper{
    display: flex;
    flex-direction: row;
    gap: 8px;
    .selected-make-primary{
      background: green;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
    }
    .selected-delete{
      background: red;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
    }
    .selected-file-name {
      background: lightgray;
      border-radius: 4px;
      text-decoration: underline dotted;
      padding: 4px;
    }
  }
}
