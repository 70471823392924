.login-page {
  & > div:first-child {
    width: 100%;
  }

  .login-container {
    display: flex;
    width: 100%;

    .pane {
      flex: 1;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logo-pane {
      background-color: #000;

      .icon {
        width: 500px;
        height: 300px;
        object-fit: cover;
      }
    }

    .error-box {
      border: 1px solid;
      display: inline-block;
      margin-top: 20px;
      padding: 5px;
      border-radius: 4px;
      color: red;
    }
    
    .login-form {
      width: 100%;
      padding: 0px 20px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      .sign-in-label {
        font-weight: 500;
        margin: 10px 0;
        font-size: 18px;
      }

      .input {
        width: 60%;
        
        input {
          width: 100%;
          height: 40px;
          border: 0;
          border-bottom: 1px solid #aaa;
          text-indent: 10px;
          background: transparent;

          &:focus, &:focus-visible {
            border-bottom: 2px solid;
            outline: none;
          }
        }
      }

      .login-btn {
        padding: 5px 10px;
        font-size: 16px;
        width: 244px;
        height: 50px;
        border-radius: 25px;

        &.disabled {
          opacity: 0.5;
        }
      }

      .forgot-pwd-btn {
        margin: 10px 0;
        width: 150px;
        height: 40px;
        border-radius: 25px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .login-page {
    font-size: 12px;

    .login-container {
      flex-direction: column;
      height: 100vh;

      .pane {
      }

      .logo-pane {
        flex-basis: 40%;

        .icon {
          width: 200px;
          height: 121px;
        }
      }

      .form-pane {
        flex-basis: 60%;
        align-items: flex-start;
        margin-top: 40px;

        .login-form {
          .input {
            width: 100%;
          }
          .login-btn {
            font-size: 14px;
            height: 35px;
            width: 120px;
          }
        }
      }

    }
  }
}
