.main-content {
	z-index: 1;

	.list-actions {
		display: flex;
		align-items: center;
		margin: 20px 0;
		gap: 20px;
		padding: 0px;

		.title {
			font-weight: bold;
		}

		.view-type-selection-wrap {
			display: flex;
			gap: 5px;
			margin-left: auto;

			.view-type-option {
				padding: 2px;
				background-color: #fff;
				width: 21px;
				height: 21px;
				border-radius: 6px;
				cursor: pointer;

				&.view-type-grid {
					&.selected img {
						filter: brightness(0) saturate(100%) invert(100%)
							sepia(16%) saturate(7496%) hue-rotate(180deg)
							brightness(104%) contrast(117%);
					}

					img {
						filter: none;
					}
				}

				&.view-type-list {
					img {
						filter: brightness(0) saturate(100%) invert(57%)
							sepia(15%) saturate(2313%) hue-rotate(175deg)
							brightness(88%) contrast(84%);
					}

					&.selected img {
						filter: none;
					}
				}

				img {
					margin-bottom: 11px;
				}

				&.selected {
					background-color: #478cd1;
				}
			}
		}

		.add-device-btn {
			margin-left: auto;
			width: 103px;
			height: 30px;
			border-radius: 6px;
			background: #478cd1;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			color: #fff;
			cursor: pointer;

			&:after {
				content: " + ";
				margin-left: 5px;
			}
		}
	}

	.device-detail-container {
		display: grid;
		grid-template-columns: 3fr 2fr;
		margin: 20px 0;
		gap: 20px;
		padding: 10px 20px;
		border-radius: 5px;
		box-shadow: 0px 0px 4px 1px #00000040;

		.device-detail {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			margin: 20px 0;
			gap: 20px;
			padding: 10px 20px;

			.label {
				font-weight: 600;
			}
		}
	}

	.site-detail-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.button-controller {
			display: flex;
			flex-direction: row;
			gap: 20px;
			.edit-button-wrapper {
				width: 101px;
				height: 30px;
				border-radius: 6px;
				background: #478cd1;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.edit-button-text {
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					color: #fff;
				}
				.edit-icon {
					width: 12px;
					height: 12px;
				}
			}
			.delete-button-wrapper {
				width: 101px;
				height: 30px;
				border-radius: 6px;
				border: 1px solid #bf2137;
				background: #fff;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.delete-button-text {
					color: #bf2137;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
				}
				.delete-icon {
					width: 12px;
					height: 12px;
				}
			}
		}
	}
}

.modal {
	.modal-dialog {
		max-width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.modal-content {
			width: 930px;
			height: fit-content;
			overflow: hidden;
			.modal-header {
				padding: 15px 30px;
			}

			.modal-body {
				padding: 15px 30px;
				width: 100%;

				.device-modal-wrapper {
					.add-device-form {
						display: flex;
						flex-direction: row;
						width: 100%;
						gap: 30px;
						height: max-content;

						.input {
							width: 50%;
							margin-bottom: 0px;
							flex-direction: column;
							gap: 10px;

							.name-wrapper {
								width: 100%;
								display: flex;
								gap: 10px;
								flex-direction: column;

								.text-wrapper {
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
								}
							}

							.map-wrapper {
								display: flex;
								gap: 10px;
								flex-direction: column;

								.text-wrapper {
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
								}
							}
						}

						.map-container {
							width: 420px;
							height: 350px;
							position: relative;

							.map {
								width: 100%;
								height: 350px;
							}

							.map-current-location-btn {
								position: absolute;
								bottom: 10px;
								left: 10px;

								.map-crosshairs {
									font-size: 0.9rem;
									color: white;
									margin-left: 5px;
								}
							}
						}
					}

					.bottom-controller {
						height: 70px;
						position: sticky;
						bottom: 0px;
						z-index: 2;
						background: #fff;
						display: flex;
						align-items: center;
						justify-content: end;
						gap: 20px;

						.cancel-modal {
							height: 30px;
							width: 100px;
							border-radius: 4px;
							border: 1px solid #478cd1;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #478cd1;
							cursor: pointer;
						}

						.submit-modal {
							height: 30px;
							width: 100px;
							border-radius: 4px;
							display: flex;
							align-items: center;
							justify-content: center;
							background: #478cd1;
							color: #fff;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

.device-location-modal.modal {
	overflow: hidden;
	width: 100%;

	.modal-dialog .modal-content {
		width: 460px;
	}
}

.device-list-wrap {
	display: inline-flex;
	flex-direction: column;
	width: 100%;
	align-items: center;

	.device-list-header {
		.title {
			padding: 10px 0;
			font-weight: bold;
			font-size: 20px;
		}

		.search-box {
			display: flex;
			justify-content: space-between;
			width: 20em;
			padding: 10px 20px;
			border: 1px solid;
			border-radius: 4px;
			max-height: 52px;
			align-items: center;

			.search-text {
				font-size: 14px;
			}

			.submit-btn {
				padding: 2px 8px;
				border: 1px solid;
				border-radius: 4px;
				background-color: black;
				color: white;
				cursor: pointer;
				font-size: 12px;
			}
		}
	}

	.device-list {
		padding: 0px;
		margin: 10px 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: auto;
		gap: 20px;

		.no-devices {
			text-align: center;
			width: 100%;
			background-color: #fff;
			border-radius: 6px;
			padding: 0 10px;
			width: 200px;
		}

		.device-grid {
			list-style-type: none;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: calc((100% - 1047px) / 2);
			padding: 0;
			margin: 0;
			row-gap: 30px;
			.device {
				background: #fff;
				text-align: left;
				box-shadow: 0px 0px 4px 2px #00000040;
				border-radius: 6px;
				width: 343px;
				cursor: pointer;

				.site-row {
					display: flex;
					flex-direction: column;
					align-items: center;
					position: relative;

					.site-name-wrap {
						display: flex;
						flex-direction: column;
						width: 100%;

						.site-name {
							font-size: 18px;
							display: inline-block;
							text-overflow: ellipsis;
							max-width: 100px;
							overflow: hidden;
							white-space: nowrap;
						}

						.site-img {
							width: 100%;
							height: 210px;
						}
					}

					.actions-wrap {
						display: flex;
						justify-content: space-between;
						width: 100%;
						padding-top: 20px;
						padding-left: 20px;
						padding-bottom: 24px;
						padding-right: 20px;
						position: relative;

						.site-name {
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
						}

						.icon-wrapper {
							display: flex;
							gap: 10px;

							.location-btn {
								padding: 2px;
								background-color: #478cd1;
								width: 26px;
								height: 26px;

								img {
									margin-bottom: 3px;
								}
							}

							.listcard-more-wrapper {
								cursor: pointer;

								.more-options-wrapper {
									display: flex;
									flex-direction: row;
									gap: 10px;
									width: max-content;
									position: absolute;
									background: #fff;
									padding: 16px 14px;
									z-index: 1;
									top: 50px;
									right: -18px;
									border: 1px solid #bbb;
									border-radius: 4px;

									.more-options-row {
										display: flex;
										flex-direction: row;
										gap: 10px;
									}
								}
							}
						}

						.show-devices-label {
							cursor: pointer;

							&.tree-arrow {
								margin: -1px 6px;
							}
						}

						.actions {
							display: flex;
							justify-content: flex-start;
							gap: 10px;

							.action-item {
								cursor: pointer;
								display: inline-block;
								font-size: 1.2em;

								&.fa-trash {
									color: #ff0000;
								}
							}
						}
					}
				}

				&:last-child {
					border: none;
				}
			}
		}

		.list {
			list-style-type: none;
			padding: 0;
			margin: 0;
			display: flex;
			gap: 10px;
			max-height: 164px;
			overflow-y: auto;
			padding: 10px;

			li {
				width: 100%;

				.device {
					display: flex;
					padding: 5px 0;
					cursor: pointer;
					border-bottom: 1px solid #ccc;
					text-align: left;

					&:last-of-type {
						border: none;
					}

					&.no-devices {
						border: none;
					}

					.device-name-wrap {
						flex: 1;

						.device-name {
							text-overflow: ellipsis;
							max-width: 68px;
							overflow: hidden;
							white-space: nowrap;
						}
					}

					.device-metrics {
						display: flex;
						align-items: center;
						gap: 10px;
						flex: 1 1;
						justify-content: center;

						.device-status {
							width: 9px;
							height: 9px;
							display: inline-block;
							background-color: red;
							border-radius: 50%;

							&.healthy {
								background-color: green;
							}
						}
					}

					.actions {
						flex: 1;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						gap: 10px;

						.action-item {
							font-size: 1.2em;

							&.fa-trash {
								color: #ff0000;
							}
						}
					}
				}
			}

			.add-new-device {
				display: inline-flex;
				cursor: pointer;
				border: 1px solid rgb(26, 115, 232);
				color: rgb(26, 115, 232);
				padding: 0.175rem 0.5rem;
				line-height: 1.4;
				font-size: 14px;
				font-weight: 500;
				border-radius: 6px;
				width: 134px;
			}
		}
	}
}

.device-list-container {
	border: 0 !important;
	text-align: left;

	.list-header {
		.list-header-item {
			&.name-column {
				min-width: 200px;
				max-width: 200px;
			}

			&.unitType-column {
				min-width: 120px;
				max-width: 120px;
			}

			&.status-column {
				min-width: 120px;
				max-width: 120px;
			}

			&.health-column {
				min-width: 120px;
				max-width: 120px;
			}

			&.actions-column {
				min-width: 320px;
				max-width: 320px;
			}
		}
	}

	.list-body {
		width: 100%;

		.list-body-cell {
			&.name-column {
				display: flex;
				flex-direction: column;

				.device-name {
					cursor: pointer;
					text-decoration: underline;
					font-weight: 500;
					text-decoration-color: #478cd1;
					color: #478cd1;
				}

				.thermostat-device-name {
					font-weight: 500;
				}
			}

			&.actions-column {
				// position: relative;

				.more-options-wrapper {
					display: flex;
					flex-direction: row;
					gap: 10px;
					padding: 16px 14px;
					border-radius: 4px;
				}

				.more-options-row {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					gap: 10px;
					background-color: #eee;
					padding: 5px;
					border-radius: 15%;
					cursor: pointer;
				}

				.icon-wrapper {
					display: flex;
					gap: 10px;

					.listcard-more-wrapper {
						cursor: pointer;

						.more-options-wrapper {
							display: flex;
							flex-direction: row;
							gap: 10px;
							width: max-content;
							position: absolute;
							background: #fff;
							padding: 16px 14px;
							z-index: 1;
							top: 30px;
							left: -95px;
							border: 1px solid #bbb;
							border-radius: 4px;

							.more-options-row {
								display: flex;
								flex-direction: row;
								gap: 10px;
							}
						}
					}
				}
			}

			&.health-column {
				img {
					padding: 8px;
					border-radius: 6px;
					width: 25px;
					height: 25px;

					&.unhealthy {
						background: #e14942;
					}

					&.healthy {
						background-color: #68b641;
					}
				}
			}

			&.status-column {
				min-width: 120px;
				max-width: 120px;

				&.Active {
					color: #68b641;
				}

				&.Inactive {
					color: #e14942;
				}
			}

			&.name-column {
				min-width: 200px;
				max-width: 200px;
			}

			&.unitType-column {
				min-width: 120px;
				max-width: 120px;
			}

			&.health-column {
				min-width: 120px;
				max-width: 120px;
			}

			&.actions-column {
				min-width: 320px;
				max-width: 320px;
			}
		}
	}
}

.carousel-image .modal-dialog {
	margin: 0;

	img {
		width: 100%;
		height: 80vh;
		object-fit: contain;
	}
}

// for mobile screens
@media only screen and (max-width: 840px) {
	.main-content {
		.site-detail-header {
			flex-direction: column !important;

			.button-controller {
				margin-top: 10px;
				justify-content: space-evenly;
				max-width: 80px;

				.edit-button-wrapper {
					max-width: 30px;

					.edit-button-text {
						display: none;
					}
				}

				.delete-button-wrapper {
					max-width: 30px;

					.delete-button-text {
						display: none;
					}
				}
			}
		}
	}

	.device-list-wrap {
		font-size: 12px;
		overflow: auto;

		.device-list {
			.device-grid {
				width: fit-content;
				grid-template-columns: auto;
				gap: 0px;

				.device {
					width: calc(100%) !important;

					.site-row {
						.actions-wrap {
							.icon-wrapper {
								.listcard-more-wrapper {
									.more-options-wrapper {
										right: 7px;
									}
								}
							}
						}
					}
				}
			}

			.list .add-new-device {
				padding: 5px;
				font-size: 10px;
				width: 94px;
			}
		}
	}

	.device-list-container {
		width: 100%;
		overflow: visible;
	}

	.modal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					.device-modal-wrapper {
						.add-device-form {
							height: 500px;
							flex-direction: column;
							overflow: scroll;

							.input {
								width: 100%;
							}

							.map-container {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.carousel-image .modal-dialog {
		img {
			height: 20vh !important;
			width: 80vw !important;
		}
	}
}
