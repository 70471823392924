.device-time-history-container {
    border-radius: 6px;
    width: 100%;
    overflow: hidden;

    .rdrDefinedRangesWrapper {
        display: none;
    }

    .thermostat-device-name {
        text-align: left;
        font-size: 24px;
        font-weight: 500;
        margin: 20px 0;
    }

    .charts-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px 0;

        .chart-list {
            height: 100vh;
            flex: 1 1 auto;
            position: relative;
            overflow: hidden auto;
            scrollbar-width: none;
            -ms-overflow-style: none; 

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .chart-row-gauge {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 40px;
            min-height: 200px;

            .chart-gauge {
                padding: 20px;
                width: 360px;
                border-radius: 6px;
                border: 1px solid rgba(71, 140, 209, 0.20);
                background: #FFF;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;

                .title-wrap {
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    text-align: left;
                }

                .chart-value {
                    bottom: 25px;
                }
            }
        }
    
        .chart-row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 30px;
            min-height: 200px;
            padding: 0 20px;
            border-radius: 6px;
            border: 1px solid rgba(71, 140, 209, 0.20);
            background: #FFF;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);

            .chart-line {
                width: 100%;
                height: 400px;
            }
        }

        .chart-box {
            border-radius: 6px;
            padding: 10px;
            background-color: #fff;
        }
    
        .signal-strength-gauge {
            width: 300px;
        }
    }

    .device-time-history-header {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .date-time-range-wrap {
            position: relative;

            .date-time-picker {
                position: absolute;
                z-index: 2;
                background: #fff;
                display: flex;
                gap: 10px;
                top: 34px;
                border: 1px solid #ccc;
                border-radius: 6px;
                right: 0;
                width: fit-content;
                padding: 10px;

                .quick-range-wrap {
                    .title {
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                    
                    .quick-ranges {
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style-type: none;
                        display: flex;
                        gap: 16px;
                        flex-direction: column;
                        font-size: 12px;
                        text-align: left;
                        width: 110px;

                        .quick-range-item {
                            cursor: pointer;
                            height: 18px;

                            .form-check {
                                display: flex;
                                gap: 10px;

                                label {
                                    cursor: pointer;
                                }
                            }

                            .form-check-input {
                                height: 15px;
                                width: 15px;
                                cursor: pointer;
                            }

                            &.checked {
                                label {
                                    font-weight: bold;
                                }
                            }

                            &:not(.checked) {
                                .form-check-input {
                                    background-color: #478CD120;
                                    border: 0;
                                }
                            }
                        }
                    }
                }

                .absolute-range-wrap {
                    .title {
                        font-weight: 500;
                        margin-bottom: 10px;
                    }

                    .date-range-selection-wrap {
                        display: flex;
                        gap: 20px;
                        align-items: center;
                        justify-content: center;

                        .input-label {
                            font-size: 12px;
                        }

                        .input-wrap {
                            text-align: left;

                            input {
                                width: 120px;
                                height: 30px;
                                border-radius: 6px;
                                border: 1px solid #aaa;
                                font-size: 12px;
                                text-indent: 10px;
                                font-weight: 500;
                            }
                        }
                    }

                    .action-btns {
                        display: flex;
                        justify-content: flex-end;
                        gap: 10px;

                        .apply-range-btn, .cancel-btn {
                            color: #478CD1;
                            background-color: transparent;
                            border: 0;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
            }
        
            .date-time-selected-wrap {
                cursor: pointer;
                padding: 2px 10px;
                border: 1px solid #478CD1;
                border-radius: 6px;

                .label {
                    font-weight: 500;
                    color: #478CD1;
                    font-size: 12px;
                    display: flex;
                    height: 30px;
                    align-items: center;
                    gap: 5px;

                    img {
                        height: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .device-time-history-container {
        .device-time-history-header {
            flex-direction: column;

            .date-time-range-wrap {
                .date-time-selected-wrap {
                    .label {
                        justify-content: center;
                    }
                }
            }
        }

        .charts-container {
            display: flex;
            flex-direction: column;
            font-size: 12px;

            .chart-list {
                margin-left: 18px;
            }

            .chart-row-gauge {
                flex-direction: column;
                gap: 30px;
            }
            
            .chart-row {
                flex-direction: column;

                .chart-line {
                    width: 345px;
                }
            }

            .signal-strength-gauge {
                width: unset;
            }
        }
    }
}
