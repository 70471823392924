.sensor-type-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sensor-type-list-wrap {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  width: min-content;

  .save-icon {
    font-size: 18px;
  }

  .cancel-edit-icon {
    font-size: 18px;
  }

  .list-body {
    width: min-content;
  }

  .id-column {
    text-align: center;
    margin-bottom: 0;
  }

  .name-column {
    text-align: center;
    cursor: unset !important;
    text-decoration: unset !important;
    color: #212529 !important;
    margin-bottom: 0;

    input {
      width: 200px;
    }
  }

  .actions-column {
    display: flex;
    gap: 10px;
    align-items: center;

    &.input {
      margin-bottom: 0;
    }

    .edit-sensor-type-btn, .delete-sensor-type-btn, .save-sensor-type-btn, .cancel-sensor-type-btn {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }

  .id-column {
    min-width: 60px;
    max-width: 60px;
  }

  .name-column {
    min-width: 300px;
    max-width: 300px;
  }

  .actions-column {
    min-width: 300px;
    min-width: 300px;
  }
}

@media only screen and (max-width: 840px) {
  .sensor-type-list-wrap {
    .name-column {
      min-width: 150px;
      max-width: 150px;
    }

    .actions-column {
      min-width: 146px;
      max-width: 146px;

      .btn-text {
        display: none;
      }
    }
  }
}
