.system-list-header {
  display: flex;

  .add-system-btn {
    &:after {
      content: " + ";
    }

    padding: 6px 16px;
    background: #478CD1;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-left: auto;
  }
}

.system-list-wrap {
  margin: 40px 0;
  display: flex;
  justify-content: center;

  .save-icon {
    font-size: 18px;
  }

  .cancel-edit-icon {
    font-size: 18px;
  }

  .list-body {
    width: min-content;

    .list-body-row {
      display: flex;
    }

    .name-column {
      display: flex;
      flex-direction: column;

      .system-name {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        text-decoration-color: #478CD1;
        color: #478CD1;
      }
    }
  }

  .id-column {
    text-align: center;
    margin-bottom: 0;
    min-width: 60px;
    max-width: 60px;
  }

  .actions-column {
    display: flex;
    gap: 10px;
    align-items: center;

    &.input {
      margin-bottom: 0;
    }
  }

  .name-column {
    min-width: 200px;
    max-width: 200px;
  }

  .actions-column {
    min-width: 200px;
    max-width: 200px;
  }

  .brand-column {
    min-width: 150px;
    max-width: 150px;
  }

  .modelNumber-column {
    min-width: 150px;
    max-width: 150px;
  }

  .refrigerantType-column {
    min-width: 150px;
    max-width: 150px;
  }

  .installedOn-column {
    min-width: 150px;
    max-width: 150px;
  }

  .size-column {
      min-width: 110px;
      max-width: 110px;
  }

  .edit-system-btn, .delete-system-btn {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
