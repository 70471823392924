.sub-header {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  .page-title {
    font-weight: bold;
    font-size: 12px;
  }

  .header-bottom-wrapper{
    display: none;
  }

  .search-container {
    margin-left: auto;
    display: flex;
    border-radius: 6px;
    border: 0.8px solid #202124;
    background: #FFF;
    box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);
    width: 115px;
    height: 20px;
    font-size: 10px;

    .input-group-prepend {
      padding: 2px 5px;

      .input-text {
        background-color: initial;
        border: 0;
      }
    }

    input.text-field {
      border: 0;
      width: 270px;
      background-color: initial;
      box-shadow: none;

      &:focus, &:focus-visible {
        border: 0;
        box-shadow: none;
        outline: none;
      }
    }
  }

  .account {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-left: 10px;
    align-items: center;
    position: relative;
    font-size: 12px;

    .username {
      cursor: pointer;
      max-width: 125px;
      overflow: hidden;
      text-overflow: clip;
      white-space: nowrap;
    }

    .account-menu {
      background: #fff;
      padding: 2px;
      border-radius: 6px;
      position: absolute;
      top: 20px;
      right: -5px;
      width: 50px;

      .account-menu-item {
        cursor: pointer;
        padding-bottom: 4px;
        border-bottom: 1px solid #aaa;

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background-color: #eaeaea;
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .sub-header {
    display: flex;
    flex-direction: column;

    .dropdown-toggle::after {
      display: none;
    }

    .header-dropdown{
      & > #dropdown-basic{
        background-color: #fff;
        color: black;
        display: flex;
        flex-direction: row;
        width: 222px;
        justify-content: space-between;
        align-items: center;
        height: 30px;
      }
    }

    .header-bottom-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      display: none;
    }

    .page-title {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: start;
      gap: 8px;
    }

    .search-container {
      width: initial;
      height: 30px;
      font-size: 16px;

      .input-group-prepend {
        display: flex;
        align-items: center;
      }
    }

    .account {
      gap: 10px;
      font-size: 16px;
      margin: 0 20px;

      .account-menu {
        top: 30px;
        right: -18px;
        width: 100px;
        padding: 5px 10px;
      }
    }
  }
}
