.org-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .add-org-btn {
        &:after {
            content: " + "
        }

        padding: 6px 16px;
        background: #478CD1;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        margin-left: 20px;
    }
}

.org-list-wrap {
    width: min-content;
    margin: 40px 0;
    display: flex;
    justify-content: center;

    .list-body {
        width: min-content;
    }

    .id-column {
        text-align: center;
        margin-bottom: 0;
    }

    .name-column {
        text-align: center;
        cursor: unset !important;
        text-decoration: unset !important;
        color: #212529 !important;
        margin-bottom: 0;

        input {
            width: 200px;
        }
    }

    .actions-column {
        display: flex;
        gap: 10px;
        align-items: center;

        .edit-org-btn, .delete-org-btn {
            display: flex;
            gap: 5px;
            align-items: center;
        }
    }

    .id-column {
        min-width: 60px;
        max-width: 60px;
    }

    .name-column {
        min-width: 300px;
        max-width: 300px;
    }

    .actions-column {
        min-width: 300px;
        min-width: 300px;
    }
}

.modal {
    .modal-dialog {
        max-width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-content {
            width: 930px;
            height: 100%;
            overflow: auto;

            .modal-header{
                padding: 15px 30px;
            }

            .modal-body {
                padding: 15px 30px;
                width: 100%;

                .org-modal-wrapper {
                    .add-org-form {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        gap: 30px;
                        height: max-content;

                        .input {
                            width: 50%;
                            margin-bottom: 0px;
                            flex-direction: column;
                            gap: 10px;

                            .name-wrapper {
                                width: 100%;
                                display: flex;
                                gap: 10px;
                                flex-direction: column;

                                .text-wrapper {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                }
                            }

                            .map-wrapper {
                                display: flex;
                                gap: 10px;
                                flex-direction: column;

                                .text-wrapper {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                }
                            }
                        }

                        .map-container {
                            width: 420px;
                            height: 350px;
                            position: relative;

                            .map {
                                width: 100%;
                                height: 350px;
                            }

                            .map-current-location-btn {
                                position: absolute;
                                bottom: 10px;
                                left: 10px;

                                .map-crosshairs {
                                    font-size: 0.9rem;
                                    color: white;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }

                    .bottom-controller {
                        height: 70px;
                        position: sticky;
                        bottom: 0px;
                        z-index: 2;
                        background: #FFF;
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        gap: 20px;

                        .cancel-modal {
                            height: 30px;
                            width: 100px;
                            border-radius: 4px;
                            border: 1px solid #478CD1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #478CD1;
                            cursor: pointer;
                        }

                        .submit-modal {
                            height: 30px;
                            width: 100px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #478CD1;
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 840px) {
    .org-list-wrap {
        .name-column {
            min-width: 150px;
            max-width: 150px;
        }

        .actions-column {
            min-width: 146px;
            max-width: 146px;

            .btn-text {
                display: none;
            }
        }
    }
}
